import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import Communication from '../icons/communication.svg'
import RedButton from './RedButton'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import GeneralSlideshow from './GeneralSlideshow'

const Container = styled.div`
position:relative;
display:flex;
flex-direction:column;
justify-content:space-evenly;
align-items:center;
text-align:justify;
gap:20px;
max-width:1400px;
margin:0 auto;
padding:0 10px;

& > h2 {
    order:1;
    text-align:center;
    font-size:24px;
    font-family:Montserrat;
    margin:0;
}

font-family:Lato;
font-size:16px;



@media(min-width:993px){
    padding:0 30px;

    & > h2 {
        display:none;
    }
    flex-direction:${props => props.left?('row'):('row-reverse')};
}
`

const TextContainer = styled.div`
position:relative;
align-items:center;
width:90%;
order:3;

& > h2 {
    display:none;
    text-align:center;
    font-size:28px;
    font-family:Montserrat;
    margin:0;
}

max-width:650px;

@media(min-width:993px){
    & > h2{
        display:block;
    }
    order:1;
}
`

const CommunicationContainer = styled.div`
position:relative;
display:flex;
flex-direction:row;
align-items:center;

#title{
    color:var(--tmain);
}

& p {
    margin:0 0 0 10px;
}

& > svg {
    width:65px;
    height:65px;
}
`

const Subcontainer = styled.div`
position:relative;
display:flex;
flex-direction:column;
align-items:center;

@media(min-width:993px){
    flex-direction:row;
    align-items:flex-end;
    justify-content:space-between;
}
`

const Spacer = styled.div`
display:${props => props.last==1?('none'):('block')};
position:relative;
height:120px;
width:100%;

&:after{
    position:absolute;
    content:'';
    top:50%;
    left:20%;
    width:60%;
    border-bottom: 1px solid var(--tgrey-light1);
}

@media(min-width:993px){
    height:250px;

    &:after{
        left:25%;
        width:50%;
    }
}
`

const Abteilung = ({data,left,last}) => (
    <div id={data.id}>
        <Container left={left?(1):(0)}>
            <h2>{data.header}</h2>
            <TextContainer>
                <h2>{data.header}</h2>
                <ReactMarkdown>{data.text}</ReactMarkdown>
                <Subcontainer>
                    {
                        data.ansprechpartner!="" &&
                        <CommunicationContainer>
                            <Communication/>
                            <div>
                                <p id="title">Ansprechpartner</p>
                                <p>{data.ansprechpartner}</p>
                            </div>
                        </CommunicationContainer>
                    }

                    {
                        data.button.text!="" && <RedButton id="button" href={data.button.link}>{data.button.text}</RedButton>
                    }
                </Subcontainer>
            </TextContainer>
            { (Array.isArray(data.imageArray))?(
                <GeneralSlideshow slides={data.imageArray}/> 
            ):(
                <div>No Images found</div>
            )
            
            }
        </Container>
        <Spacer last={last?(1):(0)}/>
    </div>

);

Abteilung.propTypes = {
    data: PropTypes.shape({
        header: PropTypes.string,
        text: PropTypes.string,
        ansprechpartner: PropTypes.string,
        button: PropTypes.shape({
            link: PropTypes.string,
            text: PropTypes.string,
        }),
        imageArray: PropTypes.arrayOf( PropTypes.shape({
            image: PropTypes.oneOfType( [PropTypes.object, PropTypes.string]),
        })),
    }),
    left: PropTypes.bool,
    last: PropTypes.bool,
};

export default Abteilung;

/*

*/