import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Abteilung from './Abteilung'


const Container = styled.div`
`

const Abteilungsliste = ({data}) => (
    <Container>
        {
            data.map((item,i) => {
                return(
                    <Abteilung data={item} left={i%2==0} last={i==data.length-1}/>
                )
            })
        }
    </Container>
);

Abteilungsliste.propTypes = {
    data: PropTypes.object,
};

export default Abteilungsliste;