import React from 'react'
import styled from 'styled-components'
import PreviewCompatibleImage from './PreviewCompatibleImage'

const Container = styled.div`
position:relative;
max-width:600px;
width:90%;
height:auto;
order:2;
margin:0 auto;
`

const ImageContainer = styled.div`
position:relative;
top:0;
left:0;
width:100%;
height:100%;
max-height:90%;
background-color:var(--twhite);
border-radius:20px;
&:hover {
    & .buttons {
        opacity:1;
    }
}
@media(min-width:993px){
    width:${props => props.showmodal==1?('70%'):('')};
    & .buttons {
        display:block;
    }
}
& .slide:nth-child(${props => props.next+1}) {
    position:absolute;
    animation:fade-out 0.5s ease-in;
    z-index:-1;
}
& .slide:nth-child(${props => props.prev+1}) {
    position:absolute;
    animation:fade-out 0.5s ease-in;
    z-index:-1;
}
& .slide:nth-child(${props => props.current+1}) {
    position:relative;
    display: block;
    animation:fade-in 0.5s ease-in;
    z-index:0;
}
@keyframes fade-in {
    0% {
        position:relative;
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fade-out {
    0% {
        position:absolute;
        display: block;
        opacity: 1;
    }
    99% {
        opacity: 0;
    }
    100% {
        display: none;
        opacity: 0;
    }
}
`

const ImageWrapper = styled.div`
text-align:center;
position:absolute;
display:none;
top:0;
transistion:1s ease;
`

const Image = styled.div `
position:relative;
width:100%;
height:auto
max-height:300px;

@media(min-width:600px) {
    max-height:600px;
}

& >* {
    height:100%;
    width:100%;
}
`

const DotContainer = styled.div`
position:absolute;
width:auto;
height:auto;
left:50%;
top:100%;
transform:translateX(-50%) translateY(-24px);
display:flex;
justify-content:center;
& span {
    display:block;
    height:16px;
    width:16px;
    background-color:var(--twhite);
    margin:0 4px;
    border-radius:50%;
    box-shadow:0px 0px 1px 1px rgba(0,0,0,0.4);
    transition:0.4s ease;
    
    &:hover {
        background-color:var(--tgrey-light);
        cursor:pointer;
    }
}
& *:nth-child(${props => props.current+1}) { 
    background-color:var(--tgrey-light);
}
@media(min-width:993px){
    transform:translateX(-50%) translateY(-30px);
    
    & span {
        height:20px;
        width:20px;
        margin:0 5px;
    }
}
`

class GeneralSlideshow extends React.Component {
    constructor(props) {
        super(props)
        this.numSlides = (props.slides==null)?(0):(props.slides.length)
        this.x0 = 0

        this.state = {
            currentSlide: 0,
            showModal: false,
        }
    }
    switchSlidesDirectional(s){
        this.setState({
            currentSlide:(((this.state.currentSlide+s)%this.numSlides)+this.numSlides)%this.numSlides,
        })
    }

    switchToSlide(i){
        this.setState({
            currentSlide:i,
        })
    }

    lockSwipe(e) {
        this.x0 = e.touches[0].clientX
    }

    swipe(e) {
        let dx = e.changedTouches[0].clientX - this.x0
        let s = Math.sign(dx)*(-1)
        if(dx!==0){
            this.switchSlidesDirectional(s)
        }
    }
    
    render() {
        return(
            <Container>
                <ImageContainer
                    prev={(this.state.currentSlide-1)%this.numSlides}
                    next={(this.state.currentSlide+1)%this.numSlides}
                    current={this.state.currentSlide}

                    onTouchStart = {(e) => this.lockSwipe(e)}
                    onTouchEnd = {(e) => this.swipe(e)}
                >
                    {
                        this.props.slides.map((item,i) => 
                        <ImageWrapper className="slide" key={i}>
                            <Image>
                                <PreviewCompatibleImage imageInfo={item} objectFit="contain"/>
                                {
                                    (this.props.slides.length>1)?(
                                        <DotContainer current={this.state.currentSlide}>
                                        {
                                            this.props.slides.map((item, i) => (
                                                <span key={i} onClick={this.switchToSlide.bind(this,i)}></span>
                                            ))
                                        }
                                    </DotContainer>
                                    ):(null)
                                }
                            </Image>
                            <p>{ item.discription }</p>
                        </ImageWrapper>
                        )
                    }
                </ImageContainer>
            </Container>
        )
    }

}
export default GeneralSlideshow