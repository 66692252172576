import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import Abteilungsliste from '../components/Abteilungsliste'
import Spacer from '../components/Spacer'

export const AbteilungenPageTemplate = ({
  list,
}) => (
    <div>
      <Helmet title="Abteilungen" defer={false}/>
      <Layout activePageIndex={2}>
        <Spacer height={100}/>
        <Abteilungsliste data={list}/>
        <Spacer height={100}/>
      </Layout>
    </div>
)

AbteilungenPageTemplate.propTypes = {
  list: PropTypes.array,
}